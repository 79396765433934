import { ApiError } from './api.error';

export class InternalServerError extends ApiError {
  originalError: any;

  constructor(error: any) {
    super();
    this.originalError = error;
  }
}
