import { Injectable } from '@angular/core';
import { throwError, BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ApiError } from './common/api.error';
import { BadInputError } from './common/bad-input.error';
import { NotFoundError } from './common/not-found.error';
import { InternalServerError } from './common/internal-server.error';
import { ListFilter, Me, TenantOnboarding, UserFilters } from '@parashift/shared/models';
import { MeService } from './airbrush/me.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  currentUser: Me;
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _currentUser: BehaviorSubject<Me>;
  currentUser$: Observable<Me> ;

  constructor(
    private meService: MeService,
    private sessionService: SessionService
  ) {
    this._currentUser = new BehaviorSubject(this.currentUser);
    this.currentUser$ = this._currentUser.asObservable();
  }

  get() {
    const listFilter = new ListFilter({ include: 'roles,roles.tenant,avatar_attachment' });

    return this.meService.findRecord('', listFilter).pipe(
      map((me: Me) => {
        this.set(me);
        return me;
      }),
      catchError(this.handleError)
    );
  }

  set(currentUser: Me) {
    this.currentUser = currentUser;
    this._currentUser.next(currentUser);
  }

  saveSettings() {
    if (this.sessionService.user_id) {
      const reset = this.currentUser.settings.reset || {};
      this.meService.findRecord('').subscribe((me: Me) => {
        this.currentUser = me;
        me.settings = {
          ...me.settings,
          filters: this.sessionService.filters,
          layout: this.sessionService.layout,
          user_navigation: this.sessionService.user_navigation,
          views: this.sessionService.views,
          show_sticky_fields: this.sessionService.show_sticky_fields,
          global_page_size: this.sessionService.global_page_size,
          stay_with_document: this.sessionService.stay_with_document,
          show_complete_validation_popup: this.sessionService.show_complete_validation_popup,
          reset
        };

        me.language = this.sessionService.language;

        if (me.hasDirtyAttributes) {
          this.meService.saveRecord(me).subscribe(currentUser => this.currentUser = currentUser);
        }
      });
    }
  }

  saveUserFilters(userFilters: UserFilters) {
    if (this.sessionService.user_id && this.currentUser) {
      const userSettings = this.currentUser.settings || {};
      userSettings['user_filters'] = userFilters;
      this.currentUser.settings = userSettings;
      this.meService.saveRecord(this.currentUser).subscribe();
    }
  }

  saveTenantOnboarding(tenant_id: number, onboarding: TenantOnboarding) {
    this.currentUser.setTenantOnboarding(tenant_id, {
      ...this.currentUser.getTenantOnboarding(tenant_id),
      ...onboarding
    });
    return of(this.currentUser).pipe(switchMap(me => me.hasDirtyAttributes ? this.meService.saveRecord(me) : of(me)));
  }

  handleError(error: Response) {
    if (error.status === 400) {
      return throwError(() => new BadInputError(error));
    }

    if (error.status === 404) {
      return throwError(() => new NotFoundError(error));
    }

    if (error.status === 500) {
      return throwError(() => new InternalServerError(error));
    }

    return throwError(() => new ApiError(error));
  }
}
